import { CommonModule, registerLocaleData } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
/* ------ LANGUAGE ---------------------*/
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

/* ------ CORE IMPORTS -----------  */
import { CoreFrontendSharedModule, E2eNavigationModule, FeatureRouterLinkResolverService } from '@core/frontend-shared';
import { CoreRouterModule, provideRootRoutes, routerProviderFactory } from '@core/frontend-shared/router';

/* ------ COMMON IMPORTS -----------  */
import { CmsFrontendSharedModule } from '@common/cms/frontend-shared';
import { ConsoleFrontendSharedModule } from '@common/console/frontend-shared';
import { FeedbackFrontendModule } from '@common/feedback/frontend';
import { LayoutFrontendSharedModule } from '@common/layout/frontend-shared';
import { LayoutContentbarsModule } from '@common/layout/frontend-shared/contentbars';
import { LayoutRouterOutletUiFullscreenModule } from '@common/layout/frontend-shared/router-outlet-fullscreen';
import { LayoutSidebarModule } from '@common/layout/frontend-shared/sidebar';
import { LocalstateFrontendSharedModule } from '@common/localstate/frontend-shared';
import { MaintenanceFrontendModule } from '@common/maintenance/frontend';
import { MessagesFrontendModule } from '@common/messages/frontend';
import { MinimalUiModule } from '@common/ui/frontend-shared';
import { UiScreenServiceModule } from '@common/ui/frontend-shared/screen-service';
import { UiTabMenuModule } from '@common/ui/frontend-shared/tabmenu';
import { VersioncheckFrontendModule } from '@common/versioncheck/frontend';
import { UiRequiredModule } from '@mpf/ui/frontend-shared';
import { LayoutFrontpageModule } from '@mpf/ui/frontend-shared/layout-frontpage';
import { UtilsFrontendSharedModule, isE2E } from '@core/frontend-shared/utils';

/* ------ LOCAL IMPORTS -----------  */
import { AccessUserPluginFrontend, UserForceNewPasswordComponent } from '@common/users/frontend-shared';
import { AppComponent } from './components/app.component';
import { DownloadSurveyComponent } from './components/download-survey/download-survey.component';
import { AppImprintComponent } from './components/imprint/imprint.component';
import { LandingpageComponent } from './components/landingpage/landingpage.component';
import { AppLoginComponent } from './components/login/login.component';
import { AppPrivacyComponent } from './components/privacy/privacy.component';
import { AppToolbarcomponent } from './components/toolbar/toolbar.component';

import features from './features';
import { RequestDemoComponent } from './components/request-demo/request-demo.component';
import { ContactDemoRequestComponent } from '@mpf/contact/frontend-shared';
import { CoursesContinueLastCourseComponent } from '@mpf/courses/frontend-shared';

registerLocaleData(localeDe);





 

const preloadCmsModulesForRoutes = [
	'/','/privacy','/imprint'
]

const states: any[] = [];

const persist: string[] = ['local','language'];

@NgModule({
	declarations: [
		AppComponent,
		AppImprintComponent,
		AppPrivacyComponent,
		LandingpageComponent,
		DownloadSurveyComponent,
		AppLoginComponent,
		AppToolbarcomponent,
		RequestDemoComponent,
	],
	imports: [
		E2eNavigationModule,
		BrowserModule,
		ServiceWorkerModule.register('ngsw-worker.js', { 
			enabled: IS_PRODUCTION, 
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000' 
		}),
		ReactiveFormsModule,

		// must be imported BEFORE Core library!
		NgxsModule.forRoot(states, { 
			developmentMode: !IS_PRODUCTION,
			selectorOptions: { suppressErrors: false }
		}),
		NgxsStoragePluginModule.forRoot({ key: persist }),
		(!IS_PRODUCTION) ? NgxsReduxDevtoolsPluginModule.forRoot({}) : [],

		CoreFrontendSharedModule.forRoot({}),
		UserForceNewPasswordComponent,
		
		RouterModule.forRoot([/* routes provided using provideRootRoutes()! */], { 
			initialNavigation: 'enabledBlocking',
			paramsInheritanceStrategy:'always',
			scrollPositionRestoration:'top',
			// anchorScrolling: 'enabled',
		}),
		CoreRouterModule,
		BrowserAnimationsModule.withConfig({
			disableAnimations: isE2E(),
		}),
		ConsoleFrontendSharedModule,
		// MessagesFrontendModule,
		
		UtilsFrontendSharedModule,
		CmsFrontendSharedModule.forRoot({preloadCmsModulesForRoutes}),
		MessagesFrontendModule,
		LayoutFrontpageModule,
		LocalstateFrontendSharedModule,
		UiScreenServiceModule.forRoot(),
		MinimalUiModule.forRoot(),
		UiRequiredModule,
		UiTabMenuModule,
		FeedbackFrontendModule,

		LayoutFrontendSharedModule.forRoot(),
		LayoutContentbarsModule,
		LayoutSidebarModule,
		LayoutRouterOutletUiFullscreenModule,

		VersioncheckFrontendModule,
		MaintenanceFrontendModule,

		...features,

		ContactDemoRequestComponent,
		CoursesContinueLastCourseComponent,
		
	],
	providers: [
		{ provide: APP_ID, useValue: 'mpf-frontend-ssr' },
		{provide: LOCALE_ID, useValue: "de-DE"},
		provideHttpClient(
			withInterceptorsFromDi(),
			withFetch(), 
		),
		provideClientHydration(),
		provideRootRoutes([
			{ path: 'login', data:{ pageTitle:'Login/Anmeldung', layout:'fullscreen,showBottomBar' }, component: AppLoginComponent },
			{ path: 'imprint', data:{ pageTitle:'Impressum' }, component: AppImprintComponent },
			{ path: 'privacy', data:{ pageTitle:'Datenschutz' }, component: AppPrivacyComponent },
			{ path: 'survey', data:{ pageTitle:'Fragebogen herunterladen', layout:'fullscreen,showBottomBar' }, component: DownloadSurveyComponent,	},
			{ path: 'request-demo', data:{ pageTitle:'Jetzt gratis testen!', layout:'fullscreen,showBottomBar' }, component: RequestDemoComponent,  },
			{ path: '', pathMatch: 'full', component: LandingpageComponent, data: {layout:'fullscreen,showBottomBar'}	},
		]),
		...routerProviderFactory(),
	],
	bootstrap: [AppComponent],
	exports: [
		CommonModule,
		UiRequiredModule,
	]
})
export class AppModule {
	constructor(
		test:FeatureRouterLinkResolverService,
	) {}
}
